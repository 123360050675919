import { LangKeys } from "locales/i18n";
import { action, makeObservable, observable } from "mobx";
import BaseStore from "./BaseStore";

class UIStore extends BaseStore {
  @observable public isGlobalLoading = false;
  @observable public languageLoading = false;
  @observable public useAnimation = true;
  @observable public language = localStorage.getItem("@lang") || LangKeys.en;
  @observable public isLogin = !!localStorage.getItem("token");
  @observable public loginModal = {
    isShow: false,
  };
  @observable public lastPricesToken = {};

  constructor(depsContainer: any) {
    super(depsContainer);
    makeObservable(this);
  }
}

export default UIStore;
