import { useMediaQuery, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LogoTextSvg } from 'assets/icons';
import PageFooter from 'components/Footer';
import Header from 'components/Header';
import Flex from 'components/common/Flex';
import Text from 'components/common/Text';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { AppTheme } from 'styles/theme';

interface GuildeLinesPageProps {}

const GuildeLinesPage = (props: GuildeLinesPageProps) => {
  const styles = useStyles(props);
  const history = useHistory();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Flex
      width="100vw"
      height={'100vh'}
      className={styles.pageContainer}
      bgcolor={'rgba(255, 255, 255)'}
      column
      centerY
    >
      <Header bgcolor={'rgba(255, 255, 255)'} />

      <Flex width={'100%'} flex={1} column mb={mdDown ? 0 : 15} py={4} px={mdDown ? 6 : 2} maxWidth={1200}>
        <Flex width={'100%'} justifyContent={'flex-end'}>
          <LogoTextSvg style={{ width: 120 }} />
        </Flex>

        <Text
          style={{
            fontWeight: 700,
            fontSize: 48
          }}
          color={`#E85130`}
          mb={1}
        >
          Pumpkin Guidelines
        </Text>

        <Text component={'span'} color="#18181B" whiteSpace={'break-spaces'} mt={2} style={{ textAlign: 'justify' }}>
          {`Welcome to Pumpkin Dating! We are excited to have you as a user of our dating app. To ensure that everyone has a safe and enjoyable experience, we have established the following guidelines. By using Pumpkin, you agree to comply with these guidelines, as well as our Terms of Use and Privacy Policy.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            1. Be Respectful
          </Text>
          {`
Respect is the cornerstone of any healthy relationship. Treat others as you would like to be treated. Do not use language or behavior that is discriminatory, offensive, threatening, or harassing. We have a zero-tolerance policy for hate speech and will take action against any user who violates this guideline.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            2. Be Honest
          </Text>
          {`
Pumpkin is a platform for honest and genuine connections. Do not use fake profiles or misrepresent yourself in any way. Be truthful about your identity, age, and other relevant information.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            3. Protect Your Privacy
          </Text>
          {`
Your privacy is important to us. Do not share personal information such as your full name, address, phone number, or financial information with other users. Use the in-app messaging system to communicate with other users until you feel comfortable sharing more personal information.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            4. Report Suspicious Behavior
          </Text>
          {`
If you encounter any suspicious or inappropriate behavior on Pumpkin, please report it immediately to our support team. We will investigate all reports and take appropriate action, which may include removing the offending user from the app.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            5. Be Mindful of Scams
          </Text>
          {`
Pumpkin is not responsible for any financial transactions that take place between users. Be cautious of any requests for money or other financial information. Do not share your personal or financial information with anyone you have not met in person.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            6. Use Common Sense
          </Text>
          {`
Use your common sense when using Pumpkin. If something seems too good to be true, it probably is. Trust your instincts and do not put yourself in a potentially dangerous or uncomfortable situation.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            7. Follow the Law
          </Text>
          {`
Pumpkin is intended for users who are at least 18 years old. Do not use the app if you are under 18 years old. Users must also comply with all applicable laws and regulations when using Pumpkin.

`}
          <Text component={'span'} style={{ fontWeight: 'bold', fontSize: 18 }}>
            8. Stay Up-to-Date
          </Text>
          {`
We may update these guidelines from time to time. We encourage you to check back regularly for any updates. If you continue to use Pumpkin after we make changes to these guidelines, it will constitute your acceptance of those changes.
Thank you for being a part of the Pumpkin community. We wish you a safe and enjoyable experience!
`}
        </Text>
      </Flex>

      <Flex className={styles.footerWrap} mt={mdDown ? 8 : 1.6}>
        <PageFooter isHome />
      </Flex>
    </Flex>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    pageContainer: {
      overflowX: 'hidden',
      overflowY: 'auto'
    },
    sessionWrap: {
      width: '100%'
    },
    footerWrap: {
      width: '100%'
    }
  })
);

export default observer(GuildeLinesPage);
